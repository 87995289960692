import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "exciting-changes"
    }}>{`Exciting changes`}</h2>
    <p>{`The Carbon team is excited to announce the release of Carbon v10, a major update to IBM’s open-source design system.`}</p>
    <h3 {...{
      "id": "new-look-for-carbon"
    }}>{`New look for Carbon`}</h3>
    <p>{`The new `}<a parentName="p" {...{
        "href": "https://www.ibm.com/design/language/"
      }}>{`IBM Design Language`}</a>{` represents a complete overhaul of IBM’s design ethos. Designed from the ground up as a unified, flexible, and expressive set of principles, guidelines, and assets, the new design language redefines the essence of “IBM Design.”`}</p>
    <p>{`The changes to the Carbon Design System reflect the new IBM Design Language. Carbon is the applied usage of the IBM Design Language for web and software products and offerings.`}</p>
    <h3 {...{
      "id": "redesigned-website"
    }}>{`Redesigned website`}</h3>
    <p>{`With an all-new look and feel based on the new IBM Design Language standards, the Carbon website is better than ever. The site features improved information architecture, a new `}<a parentName="p" {...{
        "href": "/help/support"
      }}>{`help`}</a>{` section, and faster performance, ensuring that users can find exactly what they need in record time.`}</p>
    <h3 {...{
      "id": "updated-components"
    }}>{`Updated components`}</h3>
    <p>{`The Carbon component library has been updated. The updated components are the functional and visual embodiment of IBM Design Language at the product level. They are available for production use by designers and developers.`}</p>
    <h3 {...{
      "id": "revised-guidelines"
    }}>{`Revised guidelines`}</h3>
    <p>{`Carbon’s design guidelines have also been revised to reflect the new IBM Design Language. Guidance on color, layout (including the “2x” grid system), typography, iconography, motion, and more has been updated to our new standards, ensuring that all users can start with a solid foundation as they design and develop digital products and experiences.`}</p>
    <h3 {...{
      "id": "updated-accessibility-guidelines"
    }}>{`Updated accessibility guidelines`}</h3>
    <p>{`Carbon v10 comes with revised accessibility guidelines, including new guidelines for color and keyboard interactions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      